<template>
  <section class="banner">
    <div class="wrapper">
      <div class="banner__wrapper">
        <div class="banner__content">
          <h2 v-if="title" class="banner__title">{{ title }}</h2>
          <div v-if="text" class="banner__desc text-block" v-html="text"></div>
          <CButtonTrigger
            v-if="buttonTrigger && buttonText"
            :button-title="buttonText"
            :button-trigger="buttonTrigger"
          />
        </div>

        <UIPictureTag
          v-if="image.source"
          :image-seo="imageSeo"
          :image-webp="`${url}${image.webp}`"
          :image-original="`${url}${image.source}`"
        />
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import UIPictureTag from '~/components/UI/picture/UIPictureTag.vue';
import './styles.scss';
import type { BlockButtonTrigger, ImageItem, ImageSEO } from '~/types/pages';
import { useRuntimeConfig } from '#imports';
import CButtonTrigger from '~/components/buttonTrigger/CButtonTrigger.vue';

const url = useRuntimeConfig().public.siteUrl;

interface Props {
  title: string;
  text: string;
  buttonText?: string;
  image: ImageItem;
  imageSeo?: ImageSEO;
  buttonTrigger: BlockButtonTrigger | null;
}

defineProps<Props>();
</script>

<style scoped></style>
